import React, { useState, useEffect } from 'react';
import annyang from 'annyang';

const SpeechRecognitionComponent = () => {
  const [transcription, setTranscription] = useState('');

  useEffect(() => {
    annyang.addCommands({
      'start transcribing': startTranscribing,
      'stop transcribing': stopTranscribing,
    });

    annyang.debug(); // Uncomment this line to enable debugging for better understanding

    // Start listening
    annyang.start();

    return () => {
      annyang.abort();
    };
  }, []); // Empty dependency array ensures useEffect runs once when the component mounts

  const startTranscribing = () => {
    setTranscription(''); // Clear previous transcription
    console.log('Start transcribing...');
    // Add your logic to start transcribing here
  };

  const stopTranscribing = () => {
    console.log('Stop transcribing...');
    // Add your logic to stop transcribing here
  };

  return (
    <div>
      <p>Transcription: {transcription}</p>
    </div>
  );
};

export default SpeechRecognitionComponent;
