import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route,useParams ,Routes} from "react-router-dom";
import { Form, useLoaderData, useActionData } from "@remix-run/react";
import {json} from "@remix-run/cloudflare";
import {ToggledBackground} from './ModeSwitch.jsx'
import FullScreenImageBanner from './four4banner.jsx';
import SpeechRecognitionComponent from './SpeechReco.jsx'
 // createRoot(container!) if you use TypeScript

 //create a component to print hello world


const MeetMoPlayerComponent = () => {
  const apiUrl = `https://t-mobile.meetmo.io/readMode`;
  
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const status = data.status; // Assuming the JSON response has a property called 'status'
                console.log('Status:', status);
                if (status === 'YT'){
                    window.location.replace('https://www.youtube.com/embed/vOU1vDakduk?autoplay=1');
                }
               
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

  var { quality } = useParams();
  let pfile = 't-mobile_f1_h264_15mbit_software.mp4';

  if (quality==='4k') {
    pfile = 't-mobile_f1_h264_15mbit_software.mp4';
  }
  else if (quality==='8k') {
    pfile = 'F1_8k.mp4';
  }
    useEffect(() => {
      const head = document.querySelector("head");
      const script = document.createElement("script")
      script.setAttribute("type","text/javascript");
      script.setAttribute("src","https://pub-d6edaebcd6474ac499bdcb399e4e121c.r2.dev/kandaoplayer-1.0.6-v2.js");
      script.onload = () => {
        console.log("Script loaded!");
        if(pfile==='F1_Comp_4k.mp4'){
          let mpr = new KandaoPlayer('meetmo-playerm', `https://pub.assets.meetmo.io/${pfile}`, {
          widgets: { gyroAutoPlay: 'on',fullscreen: true, vr: false, gyro: true, rotate: false ,plane : false},
          video: { autoplay: true, loop: true}
        });
      }
      else if(pfile==='F1_8k.mp4'){
        let mp2 =  new KandaoPlayer('meetmo-playerm', `https://pub.assets.meetmo.io/${pfile}`, {
          widgets: { gyroAutoPlay: 'off',fullscreen: true, vr: false, gyro: false, rotate: true ,plane : false},
          video: { autoplay: true, loop: true}
        });
      };
       
      };
      head.appendChild(script);
      return () => {
        head.removeChild(script);
      };
    }, []);

    return (
      <div style={{ height: '100vh', width: '100%', position: 'absolute' }}>
        <div id="meetmo-playerm" style={{ margin: '0', width: '100%', height: '100%' }} />
      </div>
    );
  }
      // Initialize the MeetMOPlayer after the script is loaded
    


const App = () => (

    // Dynamically load the MeetMOPlayer script

      
  <Router>
    <Routes>
      <Route path="/4kplusbanner" element={<FullScreenImageBanner />} />
      <Route path="/switch" element={<ToggledBackground />} />
      <Route path="/speech" element={<SpeechRecognitionComponent />} />
      <Route path="/:quality" element={<MeetMoPlayerComponent />} />
      
    </Routes>
  </Router>

    


);



const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
