import React, { useEffect } from 'react';
import './styles/FullScreenImageBanner.scss';


const FullScreenImageBanner = () => {

  const apiUrl = `https://t-mobile.meetmo.io/readMode`;
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const status = data.status; // Assuming the JSON response has a property called 'status'
                console.log('Status:', status);
                if (status === 'YT'){
                    window.location.replace('https://www.youtube.com/embed/2fWy2WJLiAs?autoplay=1');
                }
               
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });

  let pfile = 't-mobile_f1_h264_15mbit_software.mp4';
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script")
    script.setAttribute("type","text/javascript");
    script.setAttribute("src","https://pub-d6edaebcd6474ac499bdcb399e4e121c.r2.dev/kandaoplayer-1.0.6-v2.js");
    script.onload = () => {

      console.log("Script loaded!");
      let mp = new KandaoPlayer('meetmo-playerb',`https://pub.assets.meetmo.io/${pfile}`, {
        fov: 360, widgets: { gyroAutoPlay: 'on',fullscreen: true, vr: false, gyro: true, rotate: false ,plane : false},
        video: { autoplay: true,loop: true, controls: true }
    });
    
    };
    head.appendChild(script);
    return () => {
    };
  }, []);


  return (
    <div style={{ height: '100vh', width: '100%', position: 'absolute' }}>
      <div id="meetmo-playerb" className="full-screen-banner" data-banner_pc="assets/banner.avif"
    data-banner_mobile="assets/banner.avif" />
    </div>
  );
};


export default FullScreenImageBanner;
