import React, { useEffect } from 'react';
import './styles/Switch.scss';

class ToggleBtn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isActive: true};        
    }
    
    toggle() {
        this.props.toggleFn();
        this.setState({isActive: !this.state.isActive});
    }

    render() {
        const state     = this.state;
        const activeCls = (state.isActive) ? ' is-active' : '';
        const activeTxt = (state.isActive) ? 'CDN Mode'         : 'Youtube Mode';
        return (
            
            <button className={'SlideBtn' + activeCls} onClick={this.toggle.bind(this)}>
                <span className="SlideBtn-knob"><br/><br/>{activeTxt}</span>
            </button>
            
        );
    }
}

class ToggledBackground extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {lightsOn: true};
        const apiUrl = `https://t-mobile.meetmo.io/readMode`;
    
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const status = data.status; // Assuming the JSON response has a property called 'status'
                console.log('Status:', status);
                if (status === 'YT'){
                    this.setState({ lightsOn: true });
                }
                else{

                    this.setState({ lightsOn: false });
                }
               
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });



    }
    
    toggleBg() {
        this.setState({lightsOn: !this.state.lightsOn});
        const mode = !this.state.lightsOn ? 'CDN' : 'YT';
        const apiUrl = `https://t-mobile.meetmo.io/readMode?change=${mode}`;
        console.log(apiUrl);
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const status = data.status; // Assuming the JSON response has a property called 'status'
                console.log('Status:', status);
                if (status === 'YT'){
                    
                    this.setState({ lightsOn: false });
                }
                else if (status === 'CDN'){

                    this.setState({ lightsOn: true });
                }
                
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }
    
    render() {
        
        const gr = (this.state.lightsOn) ? 'ToggledBackground-lightsOn':'';
        return (
            <div className={'ToggledBackground ' + gr}>
                <ToggleBtn toggleFn={this.toggleBg.bind(this)} />
            </div>
        );
    }
}

export {ToggledBackground};

